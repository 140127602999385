import Axios from "axios";
import { getToken } from "../Entities/User/userManager";
import { serverUrl } from "../Commons/globalVars";


const makeGetCall = (path, successHandler, errorHandler) => {
  path = path.replace('`', "&grave;");
  return Axios.get(`${serverUrl}/${path}`, {
    headers: {
      token: getToken(),
    }
  }).then((response) => {
    return successHandler(response)
  }).catch(function (error) {
    return errorHandler(error);
  });
}

const makePutCall = (path, jsonParams, successHandler, errorHandler) => {
  return Axios.put(`${serverUrl}/${path}`, jsonParams, getCommonHeaders())
    .then((response) => {
      return successHandler(response);
    }).catch(function (error) {
      return errorHandler(error);
    });
}

const makePostCall = (path, jsonParams, successHandler, errorHandler) => {
  return Axios.post(`${serverUrl}/${path}`, jsonParams, getCommonHeaders())
    .then((response) => {
      return successHandler(response);
    }).catch(function (error) {
      return errorHandler(error);
    });
}

const getCommonHeaders = () => {
  return {
    headers: {
      token: getToken(),
    }
  }
}

export {
  makeGetCall,
  makePutCall,
  makePostCall,
}