import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "../ActionButton";

const VisitLine = (props) => {
  const [visit, setVisit] = useState({});
  useEffect(() => {
    setVisit(props?.visit);
  }, [props]);
  let history = useNavigate();
  const handleClickEdit = (e) => {
    history("/editVisit", { state: { patient: props.patient, visit: props.visit } });
  }
  const handleClickView = (e) => {
    history("/viewVisit", { state: { patient: props.patient, visit: props.visit } });
  }
  return (
    <div key={visit.id} className="alert alert-light" role="alert">
      <div className="row">
        <div className="col">
          Reason: {visit.reason} {visit.lastName}  <br />
          Date And Time: {visit.dateAndTime} <br />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col text-start">
          <ActionButton caption="View" onClick={handleClickView} icon={<VisibilityOutlined />} />
          <ActionButton caption="Edit" onClick={handleClickEdit} icon={<EditOutlined />} />
        </div>
      </div>
    </div>
  )
}
export default VisitLine;