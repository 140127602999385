import { useNavigate } from "react-router-dom";

const UserLine = (props) => {
  let history = useNavigate();
  const handleClickEdit = (e) => {
    history("/editUser", { state: { user: props?.user } });
  }
  const handleClickView = (e) => {
    history("/viewUser", { state: { user: props?.user } });
  }
  return (
    <div key={props?.user?.id} className="alert alert-light" role="alert">
      <div className="row">
        <div className="col">{props?.user?.username} - {props?.user?.firstName} {props?.user?.lastName}</div>
        <div className="col text-end">
          <button className="btn btn-warning" onClick={handleClickView}>view</button>
          &nbsp;
          <button className="btn btn-warning" onClick={handleClickEdit}>Edit</button>
        </div>
      </div>
    </div>
  )
}
export default UserLine;