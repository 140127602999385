import { makeGetCall, makePostCall, makePutCall } from "./mainHook";

const getAppointmentsByRange = (fromDate, toDate, successHandler, errorHandler) => {
  // todo make sure dates are correctly formatted
  makeGetCall(`appointment/by/range/?fromDate=${fromDate}&toDate=${toDate}`, successHandler, errorHandler);
}

const getAppointmentById = (id, successHandler, errorHandler) => {
  makeGetCall(`appointment/${id}`, successHandler, errorHandler);
}

const getAppointmentsByPatientId = (patientId, successHandler, errorHandler) => {
  makeGetCall(`appointment/patient/${patientId}`, successHandler, errorHandler);
}

const updateAppointment = (params, successHandler, errorHandler) => {
  return makePutCall("appointment", params, successHandler, errorHandler);
}

const addAppointment = (params, successHandler, errorHandler) => {
  return makePostCall("appointment", params, successHandler, errorHandler);
}

const getAppointmentClosureTypes = (successHandler, errorHandler) => {
  makeGetCall(`appointment/closure/type`, successHandler, errorHandler);
}

const closeAppointment = (appointmentId, closureTypeId, successHandler, errorHandler) => {
  const params = new URLSearchParams();
  params.append('closureTypeId', closureTypeId);
  return makePutCall(`appointment/close/${appointmentId}`, params, successHandler, errorHandler);
}

export {
  getAppointmentsByRange,
  getAppointmentById,
  updateAppointment,
  addAppointment,
  getAppointmentsByPatientId,
  getAppointmentClosureTypes,
  closeAppointment,
}