import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PatientLine from '../Components/Patient/PatientLine';
import AddButton from '../Components/AddButton';
import { handleResponseError } from '../Commons/errorHandler';
import { getAllPatients } from '../Hooks/patientHook';
import ErrorAlert from '../Components/ErrorAlert';

function Patients() {
  const [errorsList, setErrorsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [patientsList, setPatientsList] = useState([]);
  let history = useNavigate();
  const getPatientsList = () => {
    let successHandler = (response) => {
      setPatientsList(response.data)
      return response.data;
    }
    let errorHandler = (error) => {
      console.log(error?.response)
      return handleResponseError(error, setErrorsList);
    }
    return getAllPatients(searchText, successHandler, errorHandler);
  }

  const onSearchChange = (e) => {
    //console.log(e.target.value)
    setSearchText(e.target.value);
    //refetch()
  }
  useEffect(() => {
    sessionStorage.removeItem("selectedPatientId");
  }, [])

  useEffect(() => {
    getPatientsList();
  }, [searchText]);

  return (
    // actions
    <div>
      <h1>Patients List</h1>
      <ErrorAlert errors={errorsList} hide={errorsList.length === 0} />
      <AddButton onClickHandler={() => { history('/addPatient'); }} />
      <div>
        <input type="text" placeholder="Search" className="mb-2" onChange={onSearchChange} />
      </div>
      {
        patientsList?.map((patient) => {
          return (
            <PatientLine key={patient.id} patient={patient} />
          );
        })
      }
    </div>
  )
}

export default Patients