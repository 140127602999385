import LabeledInput from "./LabeledInput";

const ListGroupItem = (props) => {
  return (
    <li className="list-group-item">
      <LabeledInput type={props.type} label={props.label} id={props.id}
        validationMessage={props.validationMessage} onChangeHandler={(e) => props.onChangeHandler(e)}
        value={props.value} invalid={props.invalid}/>
    </li>
  );
}
export default ListGroupItem;