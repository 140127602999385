import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Patients from './Pages/Patients';
import Appointments from './Pages/Appointments';
import Users from './Pages/Users';
import MainNavbar from './Components/MainNavbar';
import Login from './Pages/Login';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AddEditUser from './Pages/AddEditUser';
import { getToken } from './Entities/User/userManager';
import AddEditPatient from './Pages/AddEditPatient';
import PatientVisits from './Pages/PatientVisits';
import AddEditVisit from './Pages/AddEditVisit';
import AddEditAppointment from './Pages/AddEditAppointment';
import PatientAppointments from './Pages/PatientAppointments';
import ChangePassword from './Pages/ChangePassword';
import PatientFullProfile from './Pages/PatientFullProfile';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  // setCurrentUser using `useEffect`
  useEffect(() => {
    let token = getToken();
    setLoggedIn(token != null && token !== "null")
  }, [getToken()])
  const client = new QueryClient();
  return (
    <>
      <QueryClientProvider client={client}>
        {loggedIn ? <MainApp currentUser={currentUser} /> : <Login setCurrentUser={setCurrentUser} />}
      </QueryClientProvider>
    </>
  )
}
function MainApp(props) {
  return (
    <div className="App">
      <Router>
        <header>
          <MainNavbar />
        </header>
        <main>
          <div className="container ms-1 mt-3" style={{ height: '80vh', overflowY: 'auto', width: '100%' }}>
            <Routes>
              <Route path="/" element={<Home currentUser={props.currentUser} />} />
              <Route path="/home" element={<Home currentUser={props.currentUser} />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route path="/addAppointment" element={<AddEditAppointment />} />
              <Route path="/editAppointment" element={<AddEditAppointment />} />
              <Route path="/viewAppointment" element={<AddEditAppointment />} />
              <Route path="/patient/appointments" element={<PatientAppointments />} />
              <Route path="/patients" element={<Patients />} />
              <Route path="/addPatient" element={<AddEditPatient />} />
              <Route path="/editPatient" element={<AddEditPatient />} />
              <Route path="/viewPatient" element={<AddEditPatient />} />
              <Route path="/patient/visits" element={<PatientVisits />} />
              <Route path="/addVisit" element={<AddEditVisit />} />
              <Route path="/editVisit" element={<AddEditVisit />} />
              <Route path="/viewVisit" element={<AddEditVisit />} />
              <Route path="/users" element={<Users />} />
              <Route path="/addUser" element={<AddEditUser />} />
              <Route path="/editUser" element={<AddEditUser />} />
              <Route path="/viewUser" element={<AddEditUser />} />
              <Route path="/myProfile" element={<AddEditUser />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/fullProfile" element={<PatientFullProfile />} />
              <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default App;
