const SaveButton = (props) => {
  return (
    <div className="row">
      <div className="col">
        <button type="button" id="save-button" className="btn btn-primary" onClick={props.onClickHandler}>Save</button>
      </div>
    </div>
  )
}

export default SaveButton