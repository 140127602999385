import { useEffect, useState } from 'react'
import UserLine from '../Components/UserLine';
import AddButton from '../Components/AddButton';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../Hooks/userHook';
import { handleResponseError } from '../Commons/errorHandler';
import ErrorAlert from '../Components/ErrorAlert';

export default function Users() {
  useEffect(() => {
    sessionStorage.removeItem("selectedUserId");
  }, [])
  const [errorsList, setErrorsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState([]);
  let history = useNavigate();
  const getUserData = () => {
    let successHandler = (response) => {
      setUserData(response.data)
      return response.data;
    }
    return getAllUsers(searchText, successHandler, (error) => { return handleResponseError(error, setErrorsList) });
  }

  const onSearchChange = (e) => {
    //console.log(e.target.value)
    setSearchText(e.target.value);
    //refetch()
  }
  useEffect(() => {
    sessionStorage.removeItem("selectedUserId");
  }, [])

  useEffect(() => {
    getUserData();
  }, [searchText]);

  return (
    // actions
    <div>
      <ErrorAlert errors={errorsList} hide={errorsList.length === 0} />
      <AddButton onClickHandler={() => { history('/addUser'); }} />
      <div>
        <input type="text" placeholder="Search" className="mb-2" onChange={onSearchChange} />
      </div>
      {
        userData?.map((user) => {
          return (
            <UserLine key={user.id} user={user} id={user.id} username={user.username} firstName={user.firstName}
              lastName={user.lastName} />
          );
        })
      }
    </div>
  )
}
