import React from 'react'

function PageTitle(props) {
  return (
    <>
    <h1 className='text-center mb-4'>{props?.title}</h1>
    <hr className='mb-4'/>
    </>
  )
}

export default PageTitle