import { useEffect, useState } from "react"
import CloseButton from "./CloseButton";

function ErrorAlert(props) {
  const [hide, setHide] = useState(props.hide);
  // const [errors, setErrors] = useState([]);
  useEffect(() => {
    // setErrors(props.errors);
    setHide(props?.errors?.length === 0);
  }, [props.errors]);

  if (!hide) {
    try {
      return (
        <div style={{ width: "100%", }}>
          <div id="errorAlert-div" className="w-100" style={{ marginInlineStart: "25%" }}>
            <div className="alert alert-danger" role="alert" style={{ width: "50%" }}>
              <CloseButton onClickHandler={() => { setHide(true) }} />
              <ul>
                {
                  props.errors?.map((value, key) => {
                    return (<li key={key}> {value.charAt(0).toUpperCase() + value.slice(1)}</li>);
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      )
    } catch (error) {
      return (
        <div style={{ width: "100%", }}>
          <div id="errorAlert-div" className="w-100" style={{ marginInlineStart: "25%" }}>
            <div className="alert alert-danger" role="alert" style={{ width: "50%" }}>
              <CloseButton onClickHandler={() => { setHide(true) }} />
              <ul>
                <li>Unknown error</li>
              </ul>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ErrorAlert