import { useEffect, useRef } from "react";

const LabeledInput = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (document.getElementById(props?.id) != null) {
      document.getElementById(props?.id).value = (props.value === undefined ? "" : props?.value);
    }
  }, [props?.value]);

  useEffect(() => {
    if (props?.autoFocus !== undefined && props?.autoFocus) {
      inputRef.current.focus();
    }
  }, [props?.autoFocus]);

  return (
    <>
      <label htmlFor={props.id}><h5>{props.label}</h5></label><br />
      <input type={props.type} id={props.id}
        className={"validated-field " + (props.invalid && 'is-invalid') + (props.viewMode ? " view-mode " : "")}
        onChange={(e) => props.onChangeHandler(e)}
        disabled={props?.disabled === true ? true : false}
        size={props?.size}
        ref={inputRef}
      />
      <div className="invalid-feedback">
        {props.validationMessage}
      </div>
    </>
  )
}
export default LabeledInput;