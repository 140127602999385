// import React, { useState } from 'react'
import { Nav, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import { SidebarItems } from './SidebarItems';

const SidebarMenuItem = (props) => {
  // handle onClick to set state to active for selected menu
  let makeMenuItemActive = (e) => {
    //e.preventDefault();
    [].forEach.call(document.getElementsByClassName('sidebarMenuItem'), function (el) {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
  }
  return (
    <Nav.Link as={Link} to={props.url}
      onClick={makeMenuItemActive}
      className={
        "list-group-item list-group-item-action py-2 ripple sidebarMenuItem " + (props.active ? 'active' : '')
      }
      style={{ color: "#fff" }}
    >
      {props.icon}
      <span className="text-capitalize ms-1" keyword={props.title}>{props.title}</span>
    </Nav.Link >
  );
}
function isActive(url) {
  let currUrl = window.location.pathname.substring(1, window.location.pathname.length);
  if (url === currUrl || (currUrl === '' && url === "") || (currUrl === '' && url === "home")) {
    return true
  } else {
    return false;
  }
}
export default function MainSidebar() {
  return (
    <Stack id="sidebarMenu" className="sidebar ps-1 pe-1 pt-1 mt-4" style={{ overflowY: "auto" }}>
      {
        SidebarItems.map((value, key) => {
          return (
            <SidebarMenuItem key={value.url} title={value.title} url={value.url}
              active={isActive(value.url)}
              icon={value.icon} />
          );
        })
      }
      {/*
      <SidebarMenuItem title='dashboard' url='/home' active={true} icon={<DashboardIcon />} />
      <SidebarMenuItem url="appointments" title="Appointments" icon={<CalendarMonthOutlinedIcon />} />
      <SidebarMenuItem url="/patients" title="Patients" icon={<Diversity2OutlinedIcon />} />
      <SidebarMenuItem url="/users" title="Users" icon={<PeopleAltOutlinedIcon />} />
      */}
      <div className="p-2 mt-2 text-start text-light" style={{ backgroundColor: "#5097f1" }}>
        <span className="text-capitalize" keyword="powered_by"></span>
        <a href="https://barmajiyat.net" target='blank' className="text-light">Barmajiyat</a>
      </div>
    </Stack>
  )
}

