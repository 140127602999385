import { makeGetCall, makePostCall, makePutCall } from "./mainHook";

const getAllUsers = (searchText, successHandler, errorHandler) => {
  makeGetCall(`user?search=${searchText}`, successHandler, errorHandler);
}
const getUserById = (id, successHandler, errorHandler) => {
  makeGetCall(`user/${id}`, successHandler, errorHandler);
}

const updateUser = (params, successHandler, errorHandler) => {
  return makePutCall("user", params, successHandler, errorHandler);
}

const addUser = (params, successHandler, errorHandler) => {
  return makePostCall("user", params, successHandler, errorHandler);
}

const loginUser = (username, password, successHandler, errorHandler) => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  return makePostCall("login", params, successHandler, errorHandler);
}

const changePassword = (currentPassword, newPassword, successHandler, errorHandler) => {
  const params = new URLSearchParams();
  params.append('currentPassword', currentPassword);
  params.append('newPassword', newPassword);
  return makePutCall("user/password", params, successHandler, errorHandler);
}

export {
  getAllUsers,
  getUserById,
  updateUser,
  addUser,
  loginUser,
  changePassword,
}