import { useEffect, useState } from "react";
import SaveButton from "../Components/SaveButton";
import ErrorAlert from "../Components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import LabeledInput from "../Components/LabeledInput";
import { handleResponseError } from "../Commons/errorHandler";
import { logoutUser } from "../Entities/User/userManager";
import { changePassword } from "../Hooks/userHook";
import PageTitle from "../Components/PageTitle";

const ChangePassword = () => {
  let navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState([]);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypedNewPassword, setRetypedNewPassword] = useState("");

  useEffect(() => {
    setAllFieldsValid(currentPassword.length > 2 && newPassword.length > 2 && newPassword === retypedNewPassword)
  }, [currentPassword, newPassword, retypedNewPassword]);

  const doChangePassword = () => {
    setErrorMessages([])
    if (!allFieldsValid) {
      setErrorMessages(["Fix shown errors"]);
      return;
    }
    const successHandler = (response) => {
      setErrorMessages([]);
      navigate("/");
      logoutUser();
    }
    const errorHandler = (error) => {
      return handleResponseError(error, setErrorMessages);
    }
    changePassword(currentPassword, newPassword, successHandler, errorHandler);
  }

  return (
    <div className="me-4">
      <PageTitle title="Change Password" />
      <ErrorAlert errors={errorMessages} hide={errorMessages.length === 0} />
      {/* <CloseButton onClickHandler={() => navigate("/", { state: {} })} /> */}
      <div className="row">
        <div className="card-body">
          {/* <h3 id="pageTitle" className="card-title">Change Password</h3> */}
          <div className="row">
            <div className="col">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <LabeledInput type="password" label="Current Password" id="password-text"
                    onChangeHandler={(e) => setCurrentPassword(e.target.value)}
                    validationMessage={<>Password is a mandatory field</>}
                    invalid={currentPassword.length < 2}
                    autoFocus={true}
                  />
                </li>
                <li className="list-group-item">
                  <LabeledInput type="password" label="New Password" id="newPassword-text"
                    onChangeHandler={(e) => setNewPassword(e.target.value)}
                    validationMessage={<>"New Password" is a mandatory field</>}
                    invalid={newPassword.length < 2}
                  />
                </li>
                <li className="list-group-item">
                  <LabeledInput type="password" label="Retype Password" id="retypeNewPassword-text"
                    onChangeHandler={(e) => setRetypedNewPassword(e.target.value)}
                    validationMessage={<>"Retyped Password" and "New Password" do not match</>}
                    invalid={newPassword !== retypedNewPassword}
                  />
                </li>
                {<SaveButton2 onClickHandler={doChangePassword} />}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SaveButton2 = (props) => {
  return (
    <li className="list-group-item text-end">
      <SaveButton onClickHandler={props.onClickHandler} />
    </li>
  )
}
export default ChangePassword;