import { useEffect, useState } from "react";
import CloseButton from "../Components/CloseButton";
import SaveButton from "../Components/SaveButton";
import ErrorAlert from "../Components/ErrorAlert";
import { useNavigate } from "react-router-dom";
import LabeledInput from "../Components/LabeledInput";
import { Col, Row } from "react-bootstrap";
import { handleResponseError } from "../Commons/errorHandler";
import { addPatient, getPatientById, updatePatient } from "../Hooks/patientHook";
import PhoneInput from "react-phone-input-2";
import { defaultCountryCode } from "../Commons/globalVars";
import { getAllMaritalStatuses } from "../Hooks/maritalStatusHook";

const AddEditPatient = () => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(-1);

  const [selectedPatientData, setSelectedPatientData] = useState({
    fileNumber: "",
    firstName: "",
    lastName: "",
    father: "",
    mother: "",
    email: "",
    autoGenerateFileNumber: true,
    phoneCountryCode: defaultCountryCode,
    phone: "",
    gender: "",
    dob: "",
    maritalStatus: { id: -1 },
    address: "",
    notes: ""
  });
  const [viewMode, setViewMode] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const [maritalStatuses, setMaritalStatuses] = useState([]);
  let mainValidationMessage = <>At least <em>'First Name'</em> or <em>'Last Name'</em> or <em>'Phone'</em> should be filled</>
  let navigate = useNavigate();
  let path = document.location.pathname;
  useEffect(() => {
    //let fathersOk = !autoGenerateFileNumber || (mother === father && father.length > 2);
    let fileNumberOk = selectedPatientData.autoGenerateFileNumber || (!selectedPatientData.autoGenerateFileNumber
      && selectedPatientData.fileNumber.length > 1);
    setAllFieldsValid((selectedPatientData.firstName?.length > 1 || selectedPatientData.lastName?.length > 1
      || selectedPatientData?.phone?.length > 5) && fileNumberOk);// && fathersOk)
  }, [selectedPatientData.firstName, selectedPatientData.lastName, selectedPatientData.phone])

  // if a patient is selected load it and set mode to update
  useEffect(() => {
    if (sessionStorage.getItem("selectedPatientId") != null) {
      setSelectedPatientId(sessionStorage.getItem("selectedPatientId"));
      getPatient();
    }
    //console.log(selectedPatientId)
    if (selectedPatientId > -1) { // && Object.getOwnPropertyNames(selectedPatientData).length === 0) {
      console.log("selectedPatientId is", selectedPatientId)
    }
  }, [sessionStorage.getItem("selectedPatientId")])

  useEffect(() => {
    // if add page hide Change father
    if (path === '/addPatient') {
    } else if (path === "/viewPatient") {
      setViewMode(true)
    }
    if (path === '/viewPatient' || path === '/editPatient') {
      setSelectedPatientData({ ...selectedPatientData, autoGenerateFileNumber: true })
    }
    getMaritalStatuses();
  }, [path]);

  const getMaritalStatuses = () => {
    const successHandler = (response) => {
      setMaritalStatuses([{ id: -1, status: "select status" }, ...response.data]);
    };
    const errorHandler = (error) => {
      return handleResponseError(error, setErrorMessages);
    }
    getAllMaritalStatuses("", successHandler, errorHandler);
  }
  const getPatient = () => {
    let successHandler = (response) => {
      let patient = response.data;
      patient = { ...patient, autoGenerateFileNumber: true }
      setSelectedPatientData(patient);
      //setSelectedPatientData({ ...selectedPatientData, autoGenerateFileNumber: true })
      //return response.data;
    }
    let errorHandler = (error) => {
      console.log('patient loading error: ', error);
      return handleResponseError(error, setErrorMessages);
    };
    getPatientById(sessionStorage.getItem("selectedPatientId"), successHandler, errorHandler);
  }
  const savePatient = () => {
    setErrorMessages([])
    if (!allFieldsValid) {
      setErrorMessages(["Fix shown errors"]);
      return;
    }
    const successHandler = (response) => {
      setErrorMessages([])
      navigate("/patients")
      //return response.data;
    }
    const paramsJ = selectedPatientData;
    paramsJ['id'] = selectedPatientId;

    if (path === '/addPatient') {
      return addPatient(paramsJ, successHandler, (error) => { return handleResponseError(error, setErrorMessages) });
    } else if (path === '/editPatient') {
      return updatePatient(paramsJ, successHandler, (error) => { return handleResponseError(error, setErrorMessages) });
    } else {
      console.error("Bad patient operation")
    }
  }

  return (
    <div className="me-4">
      <ErrorAlert errors={errorMessages} hide={errorMessages.length === 0} />
      <CloseButton onClickHandler={() => navigate("/patients")} />
      <fieldset disabled={path === '/viewPatient'}>
        <div className="row">
          <div className="card-body">
            <h3 id="pageTitle" className="card-title">Add Patient</h3>
            <div className="row">
              <div className="col">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <LabeledInput type="text" label="File Number" id="fileNumber-text"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, fileNumber: e.target.value })}
                          validationMessage="Enter a valid 'File Number'"
                          value={selectedPatientData?.fileNumber}
                          invalid={selectedPatientData?.fileNumber?.length < 1 && !selectedPatientData?.autoGenerateFileNumber}
                          disabled={selectedPatientData?.autoGenerateFileNumber}
                          viewMode={viewMode}
                        />
                        {
                          !viewMode &&
                          <>
                            &nbsp;
                            <input type="checkbox" id="autoGenerateFileNumber-checkbox"
                              onChange={(e) => setSelectedPatientData({ ...selectedPatientData, autoGenerateFileNumber: e.target.checked })}
                              checked={selectedPatientData?.autoGenerateFileNumber}
                            />
                            &nbsp;Auto Generate File Number
                          </>
                        }
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <LabeledInput type="text" label="First Name" id="firstName-text"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, firstName: e.target.value })}
                          validationMessage={mainValidationMessage}
                          value={selectedPatientData?.firstName}
                          invalid={!allFieldsValid}
                          viewMode={viewMode}
                        />
                      </Col>
                      <Col>
                        <LabeledInput type="text" label="Last Name" id="lastName-text"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, lastName: e.target.value })}
                          validationMessage={mainValidationMessage}
                          value={selectedPatientData?.lastName}
                          invalid={!allFieldsValid}
                          viewMode={viewMode}
                        />
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <LabeledInput type="text" label="Father" id="father-text"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, father: e.target.value })} validationMessage="Enter a valid 'Password'"
                          value={selectedPatientData?.father}
                          viewMode={viewMode}
                        />
                      </Col>
                      <Col>
                        <LabeledInput type="text" label="Mother" id="mother-text"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, mother: e.target.value })} validationMessage="Passwords do not match"
                          value={selectedPatientData?.mother}
                          viewMode={viewMode}
                        />
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <PhoneInput
                          enableAreaCodeStretch={true}
                          enableSearch={true}
                          onChange={(value, country, e, formattedValue) => {
                            console.log(country.dialCode, value, country, e, formattedValue)
                            const countryCode = country.dialCode;
                            const phoneOnly = value.substring(country.dialCode.length, value.length);
                            setSelectedPatientData({ ...selectedPatientData, phoneCountryCode: countryCode, phone: phoneOnly })
                          }}
                          value={selectedPatientData.phoneCountryCode + selectedPatientData.phone}
                          priority={{ ga: 0, lb: 1 }}
                          defaultErrorMessage={mainValidationMessage}
                          isValid={(value, country) => {
                            return allFieldsValid
                          }}
                        />
                      </Col>
                      <Col>
                        <LabeledInput type="email" label="Email" id="email-email"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, email: e.target.value })}
                          value={selectedPatientData?.email}
                          validationMessage=""
                          viewMode={viewMode}
                        />
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <label htmlFor="gender-radio">Gender</label><br />
                        Male <input type="radio" id="gender-radio" checked={selectedPatientData?.gender === 'm'} name="gender-radio" value="m" onChange={(e) => setSelectedPatientData({ ...selectedPatientData, gender: 'm' })} />
                        &nbsp; Female <input type="radio" id="gender-radio" checked={selectedPatientData?.gender === 'f'} name="gender-radio" value="f" onChange={(e) => setSelectedPatientData({ ...selectedPatientData, gender: 'f' })} />
                        <div className="invalid-feedback">
                          Select a gender
                        </div>
                      </Col>
                      <Col>
                        <LabeledInput type="date" label="birth date" id="birthDate-date"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, dob: e.target.value })}
                          value={selectedPatientData?.dob}
                          validationMessage=""
                          viewMode={viewMode}
                        />
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <label htmlFor="gender-radio">marital status</label><br />
                        <select
                          className={"" + (viewMode ? "view-mode" : "")}
                          value={selectedPatientData?.maritalStatus?.id}
                          onChange={(e) => setSelectedPatientData({ ...selectedPatientData, maritalStatus: { id: e.target.value } })}
                        >
                          {
                            //if (maritalStatuses != null){
                            maritalStatuses?.map((value, key) => {
                              // console.log(key)
                              return (
                                <option key={key} value={value.id}
                                >
                                  {value.status}
                                </option>
                              );
                            })
                            //}
                          }
                        </select>
                        <div className="invalid-feedback">
                          Select a
                        </div>
                      </Col>
                      <Col>
                        <LabeledInput type="address" label="address" id="address-text" size="50"
                          onChangeHandler={(e) => setSelectedPatientData({ ...selectedPatientData, address: e.target.value })}
                          value={selectedPatientData?.address}
                          validationMessage=""
                          viewMode={viewMode}
                        />
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <label htmlFor="notes-text">notes</label><br />
                        <textarea id="notes-text" rows="6" cols="50"
                          onChange={(e) => setSelectedPatientData({ ...selectedPatientData, notes: e.target.value })}
                          value={selectedPatientData?.notes}
                          className={viewMode ? "view-mode" : ""}
                        />
                        <div className="invalid-feedback">
                          Add notes
                        </div>
                      </Col>
                    </Row>
                  </li>
                  {!viewMode && <SaveButton2 onClickHandler={savePatient} />}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

const SaveButton2 = (props) => {
  return (
    <li className="list-group-item text-end">
      <SaveButton onClickHandler={props.onClickHandler} />
    </li>
  )
}
export default AddEditPatient;