import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

export const SidebarItems = [
  {
    title: 'dashboard',
    url: 'home',
    icon: <DashboardIcon />,
  },
  {
    title: 'appointments',
    url: 'appointments',
    icon: <CalendarMonthOutlinedIcon />,
  },
  {
    title: 'patients',
    url: 'patients',
    icon: <Diversity2OutlinedIcon />,
  },
  {
    title: 'users',
    url: 'users',
    icon: <PeopleAltOutlinedIcon />,
  },
]