import React from 'react'

function VisitDisplay(props) {
  return (
    props?.visit &&
    <>
      <h5 className="text-light bg-dark mt-2">Visit - {props?.visit?.dateAndTime}</h5>
      <VisitDisplayLine title="Reason" value={props?.visit.reason} />
      <VisitDisplayLine title="Date And Time" value={props?.visit.dateAndTime} />
      <VisitDisplayLine title="Prescriptions" value={props?.visit.prescriptions} />
      <VisitDisplayLine title="Notes" value={props?.visit.notes} />
      <VisitDisplayLine title="Added By" value={props?.visit.addedBy.username} />
      {/* <h6></h6><span>{props?.}</span> */}
    </>
  )
}

const VisitDisplayLine = (props) => {
  return (
    <>
      <div style={{ fontWeight: "bold" }}>{props?.title}</div><span>{props?.value}</span>
    </>
  )
}
export default VisitDisplay