import { makeGetCall, makePostCall, makePutCall } from "./mainHook";

const getAllPatients = (searchText, successHandler, errorHandler) => {
  makeGetCall(`patient?search=${searchText}`, successHandler, errorHandler);
}
const getPatientById = (id, successHandler, errorHandler) => {
  makeGetCall(`patient/${id}`, successHandler, errorHandler);
}

const updatePatient = (params, successHandler, errorHandler) => {
  return makePutCall("patient", params, successHandler, errorHandler);
}

const addPatient = (params, successHandler, errorHandler) => {
  return makePostCall("patient", params, successHandler, errorHandler);
}

export {
  getAllPatients,
  getPatientById,
  updatePatient,
  addPatient,
}