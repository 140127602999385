import { useQuery } from '@tanstack/react-query';
import { makeGetCall } from '../Hooks/mainHook';
import { handleResponseError } from '../Commons/errorHandler';

function Home(props) {
  //const [stats, setStats] = useState(null);
  const { data: homeStats } = useQuery(["homeStats"],
    () => {
      return makeGetCall(
        "v/all/stats/",
        (response) => { return response.data },
        (error) => { return handleResponseError(error, null) }
      )
    },
    {
      refetchOnWindowFocus: true,
      //enabled: false
    }
  );


  return (
    <>
      <div>Welcome to Home of Shifaa</div>
      <div className="row">
        <div className="col-sm-4">
          <div className="card dashboard-card" style={{ "width": "18rem" }}>
            <div className="card-header">
              Statistics
            </div>
            <div className="card-body">
              <h5 className="card-title d-none">Statistics</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <h5>Total Patients</h5>
                  <span className="">{homeStats?.patientsCount}</span>
                </li>
                <li className="list-group-item">
                  <h5>Total Visits</h5><span>{homeStats?.visitsCount}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home