import { AlarmAdd, AssignmentInd, CalendarMonth, MedicalInformation, Person, Reorder } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "../ActionButton";

const PatientLine = (props) => {
  const [patient, setPatient] = useState({});
  useEffect(() => {
    setPatient(props?.patient);
  }, [props]);
  let history = useNavigate();
  const handleClickEdit = (e) => {
    sessionStorage.setItem("selectedPatientId", patient.id);
    history("/editPatient");
  }
  const handleClickView = (e) => {
    sessionStorage.setItem("selectedPatientId", patient.id);
    history("/viewPatient");
  }
  const handleClickAppointments = (e) => {
    history("/patient/appointments", { state: { patient: patient, referrer: "patients" } });
  }
  const handleClickAddAppointment = (e) => {
    history("/addAppointment", { state: { patient: patient } });
  }
  const handleClickVisits = (e) => {
    history("/patient/visits", { state: { patient: patient } });
  }
  const handleClickAddVisit = (e) => {
    history("/addVisit", { state: { patient: patient } });
  }
  const handleClickFullProfile = (e) => {
    history("/fullProfile", { state: { patient: patient } });
  }
  return (
    <div key={patient.id} className="alert alert-light" role="alert">
      <div className="row">
        <div className="col">
          File Number: {patient.fileNumber} <br />
          Name: {patient.firstName} {patient.lastName}  <br />
          Phone: {(patient.phoneCountryCode !== null && patient.phone) ? "+(" + patient.phoneCountryCode + ")" : ""}  {patient.phone} <br />
          Birth: {patient.dob}
        </div>
        <div className="col">
          Father: {patient.father}<br />
          Mother: {patient.mother}<br />
          Gender: {patient.gender}<br />
          Marital Status: {patient.maritalStatus?.status}<br />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col text-start">
          <ActionButton caption="View" onClick={handleClickView} icon={<Person />} />
          <ActionButton caption="Edit" onClick={handleClickEdit} icon={<AssignmentInd />} />
          <ActionButton caption="Appointments" onClick={handleClickAppointments} hide={false} icon={<CalendarMonth />} />
          <ActionButton caption="Visits" onClick={handleClickVisits} hide={false} icon={<Reorder />} />
          <ActionButton caption="Add Appointment" onClick={handleClickAddAppointment} hide={false} icon={<AlarmAdd />} />
          <ActionButton caption="Add Visit" onClick={handleClickAddVisit} hide={false} icon={<MedicalInformation />} />
          <ActionButton caption="Full Profile" onClick={handleClickFullProfile} hide={false} icon={<MedicalInformation />} />
        </div>
      </div>
    </div>
  )
}

export default PatientLine;