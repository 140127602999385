import { useEffect, useState } from "react";
import CloseButton from "../Components/CloseButton";
import SaveButton from "../Components/SaveButton";
import ErrorAlert from "../Components/ErrorAlert";
import { useNavigate, useLocation } from "react-router-dom";
import LabeledInput from "../Components/LabeledInput";
import { Col, Row } from "react-bootstrap";
import PatientInfoDisplayer from "../Components/Patient/PatientInfoDisplayer";
import { handleResponseError } from "../Commons/errorHandler";
import { addVisit, getLastVisitByPatientId, getVisitById, updateVisit } from "../Hooks/visitHook";
import { isImageFileType } from "../Commons/commons";
import { getFileByFilename } from "../Hooks/fileHook";
import { Delete } from "@mui/icons-material";
import VisitDisplay from "../Components/Visit/VisitDisplay";

const AddEditVisit = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let path = document.location.pathname;

  const [errorMessages, setErrorMessages] = useState([]);
  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [flagAttachmentForDeletion, setFlagAttachmentForDeletion] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [patient, setPatient] = useState({});
  const [lastVisit, setLastVisit] = useState(null);
  const [selectedVisitData, setSelectedVisitData] = useState({
    id: -1,
    reason: "",
    notes: "",
    prescription: ""
  });
  const [viewMode, setViewMode] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  useEffect(() => {
    if (location == null || location.state == null || location.state.patient == null) {
      setErrorMessages(["Patient not selected"]);
    } else {
      setPatient(location?.state?.patient);
    }
  }, [location, path, location?.state?.patient]);

  useEffect(() => {
    getLastVisit();
  }, [patient]);

  useEffect(() => {
    if (location == null || location.state == null || location.state.visit == null) {
      if (path !== "/addVisit") {
        setErrorMessages(["Visit not selected"]);
      }
    } else {
      setSelectedVisitData(location?.state?.visit);
      if (location.state.visit?.files[0]?.filename !== undefined) {
        let successHandler = (e) => {
          /*console.log("file data", e?.data);*/
          setAttachmentTitle(location.state.visit?.files[0]?.title);
          setImageData("data:image/jpeg;base64," + e?.data);
        }
        getFileByFilename(
          location.state.visit?.files[0]?.filename,
          successHandler,
          (error) => { return handleResponseError(error, setErrorMessages) }
        )
      }
    }
  }, [location, path, location?.state?.visit]);

  useEffect(() => {
    setAllFieldsValid(selectedVisitData.reason?.length > 1);
  }, [selectedVisitData.reason])

  useEffect(() => {
    // if add page hide Change father
    if (path === '/addVisit') {
    } else if (path === "/viewVisit") {
      setViewMode(true)
    }
    if (path === '/viewVisit' || path === '/editVisit') {
      getVisit();
    }
  }, [path, props?.patient]);

  const getLastVisit = () => {
    console.log('lastVisit', lastVisit)
    if (patient?.id === undefined) {
      return;
    }
    getLastVisitByPatientId(
      patient.id,
      (response) => setLastVisit(response.data),
      (error) => handleResponseError(error, setErrorMessages)
    );
  }

  const onChangePicture = e => {
    if (e.target.files[0]) {
      // console.log("picture: ", e.target.files);
      let file = e.target.files[0];
      setSelectedFile(file);
      let filename = file?.name;
      filename = filename.replace(/\.[^/.]+$/, "");
      setAttachmentTitle(filename)
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleDeleteAttachment = (e) => {
    //confirm first
    let confirmed = window.confirm("Are you sure you want to remove this attachment?");
    if (!confirmed) {
      return;
    }
    setAttachmentTitle("");
    setSelectedFile(null);
    setSelectedVisitData({ ...selectedVisitData, files: null });
    setImageData(null);
    setFlagAttachmentForDeletion(true);
  }
  const getVisit = () => {
    if (selectedVisitData?.id === null || selectedVisitData?.id < 1) {
      return;
    }
    let successHandler = (response) => {
      let visit = response.data;
      setSelectedVisitData(visit);
    }
    return getVisitById(selectedVisitData.id, successHandler, (error) => { return handleResponseError(error, setErrorMessages) })
  }
  const saveVisit = () => {
    setErrorMessages([])
    if (!allFieldsValid) {
      setErrorMessages(["Fix shown errors"]);
      return;
    }
    const successHandler = (response) => {
      setErrorMessages([])
      // make sure patient id is the same
      navigate("/patient/visits", { state: { patient: patient } })
      //return response.data;
    }
    const errorHandler = (error) => {
      return handleResponseError(error, setErrorMessages);
    }
    const paramsJ = selectedVisitData;
    // paramsJ['id'] = selectedVisitId;
    paramsJ['patient'] = { id: patient.id };

    let formData = new FormData();
    //const params = new URLSearchParams();
    //params.append('visit', JSON.stringify(paramsJ));
    if (path === '/editVisit') {
      // we need to prevent serverSide errors of date parsing
      paramsJ.dateAndTime = null;
      if (paramsJ?.files !== null && paramsJ?.files[0]?.dateAdded !== undefined) {
        paramsJ.files[0].dateAdded = null;
      }
    }
    formData.append('visit', JSON.stringify(paramsJ));
    formData.append("file", selectedFile)
    formData.append("attachmentTitle", attachmentTitle);
    formData.append("deleteAttachment", flagAttachmentForDeletion);
    if (path === '/addVisit') {
      return addVisit(formData, successHandler, errorHandler)
    } else if (path === '/editVisit') {
      return updateVisit(formData, successHandler, errorHandler)
    } else {
      console.error("Bad visit operation")
    }
  }

  return (
    <div className="me-4">
      <ErrorAlert errors={errorMessages} hide={errorMessages.length === 0} />
      <CloseButton onClickHandler={() => navigate("/patient/visits", { state: { patient: patient } })} />
      <fieldset disabled={path === '/viewVisit'}>
        <div className="row">
          <div className="card-body">
            <h3 id="pageTitle" className="card-title">
              Add Visit
              {
                lastVisit === null &&
                <span id="firstVisitNote-span" className="text-info">
                  &nbsp;(First Visit)
                </span>
              }
            </h3>
            <div className="row">
              <div className="col">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <PatientInfoDisplayer patient={patient} />
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <LabeledInput type="text" label="Reason" id="reason-text"
                      onChangeHandler={(e) => setSelectedVisitData({ ...selectedVisitData, reason: e.target.value })}
                      validationMessage={<>Reason is a mandatory field</>}
                      value={selectedVisitData?.reason}
                      invalid={!allFieldsValid}
                      viewMode={viewMode}
                    />
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        <label htmlFor="notes-text">notes</label><br />
                        <textarea id="notes-text" rows="6" cols="50"
                          onChange={(e) => setSelectedVisitData({ ...selectedVisitData, notes: e.target.value })}
                          value={selectedVisitData?.notes}
                          className={viewMode ? "view-mode" : ""}
                        />
                        <div className="invalid-feedback">
                          Add notes
                        </div>
                      </Col>
                      <Col>
                        <label htmlFor="prescription-text">prescription</label><br />
                        <textarea id="prescription-text" rows="6" cols="50"
                          onChange={(e) => setSelectedVisitData({ ...selectedVisitData, prescription: e.target.value })}
                          value={selectedVisitData?.prescription}
                          className={viewMode ? "view-mode" : ""}
                        />
                        <div className="invalid-feedback">
                          Add prescription
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <li className="list-group-item">
                    <Row>
                      <Col>
                        {
                          imageData?.length > 1 ?
                            <div className="border border-success-subtle p-2">
                              <img
                                src={imageData}
                                className="border"
                                alt={attachmentTitle}
                                style={{ maxWidth: "450px", maxHeight: "250px" }}
                              />
                              {
                                !viewMode &&
                                <Delete
                                  className="align-bottom text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleDeleteAttachment}
                                />
                              }
                            </div>
                            :
                            <LabeledInput type="file" label="Attachment" id="attachment-file"
                              onChangeHandler={onChangePicture}
                              validationMessage={<>Upload a valid file</>}
                              //value={selectedFile}
                              invalid={selectedFile !== undefined && !isImageFileType(selectedFile?.type)}
                              viewMode={viewMode}
                            />
                        }
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <LabeledInput type="text" label="Attachment Title" id="attachmentTitle-text"
                          onChangeHandler={(e) => setAttachmentTitle(e.target.value)}
                          validationMessage={<>Title is a mandatory field</>}
                          value={attachmentTitle}
                          invalid={selectedFile !== undefined && attachmentTitle.trim().length === 0}
                          viewMode={viewMode}
                          size={50}
                        />
                      </Col>
                    </Row>
                  </li>
                  {!viewMode && <SaveButton2 onClickHandler={saveVisit} />}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <div className="bg-secondary-subtle">
        {
          lastVisit !== null &&
          <>
            <h4 className="text-warning">Last Visit</h4>
            <VisitDisplay visit={lastVisit} />
          </>
        }
      </div>
    </div >
  );
}

const SaveButton2 = (props) => {
  return (
    <li className="list-group-item text-end">
      <SaveButton onClickHandler={props.onClickHandler} />
    </li>
  )
}
export default AddEditVisit;