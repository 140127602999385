import { useEffect, useState } from "react";
import CloseButton from "../Components/CloseButton";
import SaveButton from "../Components/SaveButton";
import { ActionButton } from "../Components/ActionButton";
import ErrorAlert from "../Components/ErrorAlert";
import { useNavigate, useLocation } from "react-router-dom";
import LabeledInput from "../Components/LabeledInput";
import { getOrderedDateTimeFormat } from "../Commons/commons";
import { handleResponseError } from "../Commons/errorHandler";
import { addAppointment, closeAppointment, getAppointmentById, getAppointmentClosureTypes, updateAppointment } from "../Hooks/appointmentHook";
import PatientInfoDisplayer from "../Components/Patient/PatientInfoDisplayer";
import PageTitle from "../Components/PageTitle";

const AddEditAppointment = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let path = document.location.pathname;

  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(-1);
  const [appointmentClosureTypes, setAppointmentClosureTypes] = useState([]);
  const [patient, setPatient] = useState({});
  const [referrer, setReferrer] = useState("appointments");
  const [selectedAppointmentData, setSelectedAppointmentData] = useState({
    id: -1,
    reason: "",
    notes: "",
    dateAndTime: getOrderedDateTimeFormat(new Date()),//new Date().toLocaleString() + "",
  });
  const [viewMode, setViewMode] = useState(false);
  const [appointmentClosed, setAppointmentClosed] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [pageTitle, setPageTitle] = useState("Add Appointment");
  const [closureTypeId, setClosureTypeId] = useState(-1);

  useEffect(() => {
    setAllFieldsValid(selectedAppointmentData.reason?.length > 1 && selectedAppointmentData?.dateAndTime.length > 6);
  }, [selectedAppointmentData?.reason, selectedAppointmentData?.dateAndTime])

  useEffect(() => {
    if (location == null || location.state == null || location.state.patient == null) {
      setErrorMessages(["Patient not selected"]);
    } else {
      setPatient(location?.state?.patient);
    }
  }, [location?.state?.patient]);

  useEffect(() => {
    if (location?.state?.referrer !== undefined) {
      setReferrer(location?.state?.referrer);
    }
  }, [location?.state?.referrer]);

  useEffect(() => {
    setErrorMessages([]);
    if (location == null || location.state == null || location.state.appointment == null) {
      if (path !== "/addAppointment") {
        setErrorMessages(["Appointment not selected"]);
      }
    } else {
      setSelectedAppointmentData(location?.state?.appointment);
      setAppointmentClosed(location?.state?.appointment?.closureType !== undefined || location?.state?.appointment?.closedOn !== null)
      setPatient(location?.state?.appointment?.patient)
    }
  }, [location?.state?.appointment]);

  useEffect(() => {
    // if add page hide Change father
    if (path === '/addAppointment') {
    } else if (path === "/viewAppointment") {
      setViewMode(true)
      setPageTitle("View Appointment");
      loadAppointmentClosureTypes();
    } else if (path === "/editAppointment") {
      setPageTitle("Edit Appointment");
    }
    if (path === '/viewAppointment' || path === '/editAppointment') {
      getAppointment();
    }
  }, [path, props?.patient]);

  const getAppointment = () => {
    if (selectedAppointmentData?.id === null || selectedAppointmentData?.id < 1) {
      return;
    }
    let successHandler = (response) => {
      let appointment = response.data;
      setSelectedAppointmentData(appointment);
    }
    return getAppointmentById(selectedAppointmentData.id, successHandler, (error) => { return handleResponseError(error, setErrorMessages) })
  }
  const saveAppointment = () => {
    setErrorMessages([])
    if (!allFieldsValid) {
      setErrorMessages(["Fix shown errors"]);
      return;
    }
    const successHandler = (response) => {
      setErrorMessages([])
      // make sure patient id is the same
      navigate("/patient/appointments", { state: { patient: patient } })
      //return response.data;
    }
    const errorHandler = (error) => {
      return handleResponseError(error, setErrorMessages);
    }
    const paramsJ = selectedAppointmentData;
    // paramsJ['id'] = selectedAppointmentId;
    paramsJ['patient'] = { id: patient.id };
    //const params = new URLSearchParams();
    //params.append('appointment', JSON.stringify(paramsJ));
    let dateAndTime = paramsJ.dateAndTime.replace(" ", "T");
    dateAndTime = dateAndTime.length > 16 ? dateAndTime.substring(0, 16) : dateAndTime;
    paramsJ["dateAndTime"] = dateAndTime;
    console.log("save params", paramsJ)
    if (path === '/addAppointment') {
      return addAppointment(paramsJ, successHandler, (error) => { return handleResponseError(error, setErrorMessages) })
    } else if (path === '/editAppointment') {
      return updateAppointment(paramsJ, successHandler, (error) => { return handleResponseError(error, setErrorMessages) })
    } else {
      console.error("Bad appointment operation")
    }
  }
  const loadAppointmentClosureTypes = () => {
    let successHandler = (response) => {
      // console.log(response.data[0])
      setAppointmentClosureTypes(response.data);
      if (closureTypeId === -1) {
        setClosureTypeId(response?.data[0]?.id);
      }
    }
    return getAppointmentClosureTypes(successHandler, (error) => { return handleResponseError(error, setErrorMessages) })
  }
  const handleAppointmentClosure = () => {
    let confirmed = window.confirm("Are you sure you want to close this appointment");
    if (!confirmed) {
      return;
    }
    const successHandler = (response) => {
      // make sure patient id is the same
      setAppointmentClosed(true);
    }
    const errorHandler = (error) => {
      handleResponseError(error, setErrorMessages);
    }
    closeAppointment(selectedAppointmentData.id, closureTypeId, successHandler, errorHandler)
  }
  return (
    <div className="me-4">
      <PageTitle title={pageTitle} />
      <ErrorAlert errors={errorMessages} hide={errorMessages?.length === 0} />
      <CloseButton onClickHandler={() => navigate(`/${referrer}`, { state: { patient: patient } })} />
      <div className="row">
        <div className="card-body">
          {/* <h3 id="pageTitle" className="card-title">Add Appointment</h3> */}
          <div className="row">
            <div className="col">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <PatientInfoDisplayer patient={patient} />
                </li>
                <fieldset disabled={path === '/viewAppointment'}>
                  <li className="list-group-item">
                    <LabeledInput type="text" label="Reason" id="reason-text"
                      onChangeHandler={(e) => setSelectedAppointmentData({ ...selectedAppointmentData, reason: e.target.value })}
                      validationMessage={<>Reason is a mandatory field</>}
                      value={selectedAppointmentData?.reason}
                      invalid={selectedAppointmentData?.reason?.length < 1}
                      viewMode={viewMode}
                    />
                  </li>
                  <li className="list-group-item">
                    <LabeledInput type="datetime-local" label="Date And Time" id="dateAndTime-text"
                      onChangeHandler={(e) => setSelectedAppointmentData({ ...selectedAppointmentData, dateAndTime: e.target.value })}
                      validationMessage={<><strong>Date And Time</strong> is a mandatory field</>}
                      value={selectedAppointmentData?.dateAndTime}
                      invalid={selectedAppointmentData?.dateAndTime.length < 1}
                      viewMode={viewMode}
                    />
                  </li>
                  <li className="list-group-item">
                    <label htmlFor="notes-text">notes</label><br />
                    <textarea id="notes-text" rows="6" cols="50"
                      onChange={(e) => setSelectedAppointmentData({ ...selectedAppointmentData, notes: e.target.value })}
                      value={selectedAppointmentData?.notes}
                      className={viewMode ? "view-mode" : ""}
                    />
                    <div className="invalid-feedback">
                      Add notes
                    </div>
                  </li>
                  {!viewMode && <SaveButton2 onClickHandler={saveAppointment} />}
                </fieldset>
                {
                  viewMode &&
                  <>
                    <li className="list-group-item text-center">
                      {
                        (appointmentClosed)
                          ?
                          "Appointment Closed"
                          :
                          <>
                            Close Appointment &nbsp;
                            <select
                              onChange={(e) => setClosureTypeId(e.target.value)}
                              disabled={false} style={{ disabled: false }}
                            >
                              {
                                appointmentClosureTypes?.map((value, key) => {
                                  return (
                                    <option key={key} value={value.id}>
                                      {value.name}
                                    </option>
                                  );
                                })
                              }
                            </select>
                            &nbsp;
                            <ActionButton
                              caption="Close Appointment"
                              onClick={handleAppointmentClosure}
                            />
                          </>
                      }
                    </li>
                  </>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SaveButton2 = (props) => {
  return (
    <li className="list-group-item text-end">
      <SaveButton onClickHandler={props.onClickHandler} />
    </li>
  )
}
export default AddEditAppointment;