import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ErrorAlert from '../Components/ErrorAlert';
import PageTitle from '../Components/PageTitle';
import VisitDisplay from '../Components/Visit/VisitDisplay';
import { getVisitsByPatientId } from '../Hooks/visitHook';
import { handleResponseError } from '../Commons/errorHandler';

function PatientFullProfile() {
  const location = useLocation();
  const [errorsList, setErrorsList] = useState([]);
  const [patient, setPatient] = useState({});
  const [visits, setVisits] = useState([]);

  const getVisits = () => {
    if (patient?.id == null || patient?.id < 1) {
      return;
    }
    let successHandler = (response) => {
      setVisits(response.data)
      return response.data;
    }
    return getVisitsByPatientId(
      patient?.id,
      successHandler,
      (error) => { return handleResponseError(error, setErrorsList) }
    )
  }

  useEffect(() => {
    if (location == null || location.state == null || location.state.patient == null) {
      setErrorsList(["Patient not selected"]);
    } else {
      setPatient(location?.state?.patient);
    }
  }, [location?.state?.patient]);

  useEffect(() => {
    getVisits();
  }, [patient]);

  return (
    <>
      <PageTitle title="Full Profile" />
      <ErrorAlert errors={errorsList} hide={errorsList?.length === 0} />
      <h4>Personal Info</h4>
      <div className="row">
        <div className="col">
          File Number: {patient.fileNumber} <br />
          Name: {patient.firstName} {patient.lastName}  <br />
          Phone: {(patient.phoneCountryCode !== null && patient.phone) ? "+(" + patient.phoneCountryCode + ")" : ""}  {patient.phone} <br />
          Birth: {patient.dob}
        </div>
        <div className="col">
          Father: {patient.father}<br />
          Mother: {patient.mother}<br />
          Gender: {patient.gender}<br />
          Marital Status: {patient.maritalStatus?.status}<br />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col">
          <h4>Visits</h4>
          <div className="title">Total Number of Visits: {visits?.length}</div><span id="Value"></span>
          <div>
            {
              visits?.map((value, key) => {
                return <VisitDisplay key={key} visit={value} />
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientFullProfile