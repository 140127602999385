import { makeGetCall } from "./mainHook";

const getAllMaritalStatuses = (searchText, successHandler, errorHandler) => {
  makeGetCall(`marital/status?search=${searchText}`, successHandler, errorHandler);
}
const getMaritalStatusById = (id, successHandler, errorHandler) => {
  makeGetCall(`marital/status/${id}`, successHandler, errorHandler);
}


export {
  getAllMaritalStatuses,
  getMaritalStatusById,
}