const getOrderedDateFormat = (d) => {
  if (d === undefined) {
    return null;
  }
  function pad(s) { return (s < 10) ? '0' + s : s; }
  //var d = date;//new Date(inputFormat)
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
}

const getOrderedDateTimeFormat = (d) => {
  if (d === undefined) {
    return null;
  }
  function pad(s) { return (s < 10) ? '0' + s : s; }
  //var d = date;//new Date(inputFormat)
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
    + " " + [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(":")
}

const isImageFileType = (fileType) => {
  fileType = (fileType === null || fileType === undefined) ? "" : fileType;
  const imageFileTypes = ['jpg', 'jpeg', 'png', 'bmp', 'jif', 'tiff'];
  return imageFileTypes.filter((val) => val.includes(fileType));
}

export {
  getOrderedDateFormat,
  getOrderedDateTimeFormat,
  isImageFileType,
}