import React, { useEffect, useState } from 'react'

export const ActionButton = (props) => {
  const [className, setClassName] = useState("");
  useEffect(() => {
    let classNameTmp = "";
    if (props?.className !== undefined) {
      classNameTmp = props.className + " ";
    }
    if (props?.hide) {
      classNameTmp += " d-none ";
    }
    if (props?.deleteButton) {
      classNameTmp += "btn btn-danger";
    } else {
      classNameTmp += "btn btn-warning";
    }
    setClassName(classNameTmp);
  }, [props?.className, props?.deleteButton]);

  return (
    <>
      <button className={className} style={{ fontSize: 'small1' }} onClick={props.onClick} title={props.title ?? props.caption}>
        {props.icon === null ? "" : props.icon}
        {props.caption}
      </button>
      &nbsp;
    </>
  )
}
