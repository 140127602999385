import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AddButton from '../Components/AddButton';
import VisitLine from '../Components/Visit/VisitLine';
import CloseButton from '../Components/CloseButton';
import ErrorAlert from '../Components/ErrorAlert';
import { Col, Row } from 'react-bootstrap';
import PatientInfoDisplayer from '../Components/Patient/PatientInfoDisplayer';
import { handleResponseError } from '../Commons/errorHandler';
import { getVisitsByPatientId } from '../Hooks/visitHook';

function PatientVisits(props) {
  const [errorsList, setErrorsList] = useState([]);
  const [patientId, setPatientId] = useState(-1);
  const [currentPatient, setCurrentPatient] = useState({});
  const [patientVisits, setPatientVisits] = useState([]);

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location == null || location.state == null || location.state.patient == null) {
      setErrorsList(["Patient not selected"]);
    } else {
      setCurrentPatient(location?.state?.patient);
    }
    setCurrentPatient(location?.state?.patient);
  }, [location?.state?.patient]);


  const getPatientVisits = () => {
    if (currentPatient?.id == null || currentPatient?.id < 1) {
      return;
    }
    let successHandler = (response) => {
      setPatientVisits(response.data)
      return response.data;
    }
    return getVisitsByPatientId(currentPatient?.id, successHandler, (error) => { return handleResponseError(error, setErrorsList) })
  }

  /*
  useEffect(() => {
    setPatientId(sessionStorage.getItem("selectedPatientId"));
  }, [sessionStorage.getItem("selectedPatientId")]);
  */

  useEffect(() => {
    // getCurrentPatient();
    getPatientVisits();
  }, [currentPatient]);

  return (
    <div>
      <h1>Patients Visits</h1>
      <CloseButton onClickHandler={() => navigate("/patients")} />
      <ErrorAlert errors={errorsList} hide={errorsList.length === 0} />
      {/*
      <div>
        <input type="text" placeholder="Search" className="mb-2" onChange={onSearchChange} />
      </div>
      */}
      <Row>
        <Col>
          <h3>
            Patient
            {
              patientVisits?.length === 0
              && <span style={{ color: "red" }}>(First Visit)</span>
            }
          </h3>
          <PatientInfoDisplayer patient={currentPatient} />
        </Col>
      </Row>
      <hr />
      <AddButton onClickHandler={() => { navigate('/addVisit', { state: { patient: currentPatient } }); }} />
      {
        patientVisits?.map((visit) => {
          return (
            <VisitLine key={visit.id} visit={visit} patient={currentPatient} />
          );
        })
      }
    </div>
  );
}

export default PatientVisits