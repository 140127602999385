import { useEffect, useState } from 'react'
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import '../App.css';
import { setCurrentUser, setToken } from '../Entities/User/userManager';
import { handleResponseError } from '../Commons/errorHandler';
import ErrorAlert from '../Components/ErrorAlert';
import { loginUser } from '../Hooks/userHook';
import LabeledInput from '../Components/LabeledInput';

function Login() {
  const [username, setUsername] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    setUsernameValid(username.length > 1);
  }, [username]);

  useEffect(() => {
    setPasswordValid(password.length > 2);
  }, [password]);

  const login = () => {
    setErrorMessages([]);
    setUsernameValid(username.length > 1);
    setPasswordValid(password.length > 2);

    if (username.length < 1 || password.length < 2) {
      return;
    }
    setToken(null);

    loginUser(
      username,
      password,
      (response) => {
        setToken(response?.data?.token);
        setCurrentUser(response?.data?.user);
        window.location.reload();
        //return response.data;
      },
      function (error) {
        handleResponseError(error, setErrorMessages);
      }
    )
  }

  return (
    <div className='LoginPage mx-auto pt-4'>
      <ErrorAlert errors={errorMessages} hide={errorMessages?.length === 0} />
      <Card style={{ width: '18rem' }}>
        <Card.Header align="center">
          Login
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item className="p-4">
            <Row className='text-center mb-2'>
              <Col>
                <img src={'images/logo-60x60.png'} alt='Shifaa' style={{ maxHeight: '40px', width: 'auto' }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <LabeledInput type="text" label="Username" id="username-text"
                  onChangeHandler={(e) => setUsername(e.target.value)}
                  validationMessage={<>Enter a valid username</>}
                  invalid={!usernameValid}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <hr />
            </Row>
            <Row>
              <Col>
                <LabeledInput type="password" label="Password" id="username-text"
                  onChangeHandler={(e) => setPassword(e.target.value)}
                  validationMessage={<>Enter a valid password</>}
                  invalid={!passwordValid}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className='text-end'>
            <Button onClick={login}>Login</Button>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div>
  )
}

export default Login