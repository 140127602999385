import React from 'react'

function PatientInfoDisplayer(props) {
  return (
    <>
      <strong>File Number: </strong>{props?.patient?.fileNumber}<br />
      <strong>Name: </strong>{props?.patient?.firstName} {props?.patient?.lastName}<br />
      <strong>Phone: </strong>{(props?.patient?.phoneCountryCode !== null && props?.patient?.phone) ? "+" + props?.patient?.phoneCountryCode + "" : ""}  {props?.patient?.phone} <br />
    </>
  )
}

export default PatientInfoDisplayer