
const getToken = () => {
  return sessionStorage.getItem("token");
}
const setToken = (token) => {
  console.log("setToken", token)
  if (token === null) {
    console.log("removing token")
    sessionStorage.removeItem("token");
  } else {
    sessionStorage.setItem("token", token);
  }
}
const setCurrentUser = (user) => {
  if (user == null) {
    sessionStorage.removeItem("cuser");
  } else {
    sessionStorage.setItem("cuser", JSON.stringify(user));
  }
}
const getCurrentUser = () => {
  let currentUser = sessionStorage.getItem("cuser");
  try {
    currentUser = JSON.parse(currentUser)
  } catch (error) {

  }
  return currentUser;
}
const logoutUser = () => {
  setToken(null);
  setCurrentUser(null);
  document.location.reload();
}
export {
  getToken,
  setToken,
  setCurrentUser,
  getCurrentUser,
  logoutUser,
}
