import { useEffect, useState } from "react";
import CloseButton from "../Components/CloseButton";
import SaveButton from "../Components/SaveButton";
import ErrorAlert from "../Components/ErrorAlert";
import { useLocation, useNavigate } from "react-router-dom";
import ListGroupItem from "../Components/ListGroupItem";
import { handleResponseError } from "../Commons/errorHandler";
import { addUser, updateUser } from "../Hooks/userHook";
import { logoutUser } from "../Entities/User/userManager";

const AddEditUser = () => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [referrer, setReferrer] = useState("/users");
  const [hideChangePasswordCheckbox, setHideChangePasswordCheckbox] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [retypedPassword, setRetypedPassword] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(-1);
  const [selectedUserData, setSelectedUserData] = useState({ firstName: "", lastName: "", username: "" });
  const [viewMode, setViewMode] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  let path = document.location.pathname;
  useEffect(() => {
    let passwordsOk = !changePassword || (retypedPassword === password && password.length > 2);
    setAllFieldsValid(firstName?.length > 1 && lastName?.length > 1 && username?.length > 2 && passwordsOk)
  }, [firstName, lastName, username, password, retypedPassword, changePassword])

  // if a user is selected load it and set mode to update
  useEffect(() => {
    if (location?.state?.user !== undefined) {
      setSelectedUserData(location?.state?.user);
    }
  }, [location?.state?.user]);

  useEffect(() => {
    console.log("referrer", location?.state?.referrer)
    if (location?.state?.referrer !== undefined) {
      setReferrer(location?.state?.referrer);
    }
  }, [location?.state?.referrer]);

  useEffect(() => {
    // if add page hide Change password
    if (document.location.pathname === '/addUser') {
      setHideChangePasswordCheckbox(true);
      // since we need to show password fields here
      setChangePassword(true);
    } else if (path === "/viewUser") {
      setViewMode(true)
      setHideChangePasswordCheckbox(true);
      setChangePassword(false);
    }
  }, [path]);

  useEffect(() => {
    getUser();
  }, [selectedUserData])

  const getUser = () => {
    if (selectedUserData !== undefined) {
      setSelectedUserId(selectedUserData?.id)
      setFirstName(selectedUserData?.firstName);
      setLastName(selectedUserData?.lastName);
      setUsername(selectedUserData?.username);
    }
  }
  const saveUser = () => {
    setErrorMessages([])
    if (!allFieldsValid) {
      setErrorMessages(["Fix shown errors"]);
      return;
    }
    const successHandler = (response) => {
      setErrorMessages([])
      if (path === '/myProfile') {
        logoutUser();
      } else {
        navigate(referrer)
      }
      //return response.data;
    }
    const paramsJ = {
      'id': selectedUserId,
      'firstName': firstName,
      'lastName': lastName,
      'username': username,
      'password': password,
    }

    if (path === '/addUser') {
      return addUser(paramsJ, successHandler, (error) => { return handleResponseError(error, setErrorMessages) });
    } else if (path === '/editUser' || path === '/myProfile') {
      const params = new URLSearchParams();
      params.append('user', JSON.stringify(paramsJ));
      params.append('changePassword', changePassword);
      return updateUser(params, successHandler, (error) => { return handleResponseError(error, setErrorMessages) });
    } else {
      console.error("Bad user operation")
    }
  }

  return (
    <div>
      {
        path === '/myProfile'
        &&
        <div className="alert alert-warning" role="alert" style={{ width: "50%" }}>
          Once your changes are saved successfully you will be logged out
        </div>
      }
      <ErrorAlert errors={errorMessages} hide={errorMessages.length === 0} />
      <CloseButton onClickHandler={() => navigate(referrer)} />
      <fieldset disabled={path === '/viewUser'}>
        <div className="row">
          <div className="card-body">
            <h3 id="pageTitle" className="card-title">Add User</h3>
            <div className="row">
              <div className="col">
                <ul className="list-group list-group-flush">
                  <ListGroupItem type="text" label="First Name" id="firstName-text"
                    onChangeHandler={(e) => setFirstName(e.target.value)} validationMessage="Enter a valid 'First Name'"
                    value={selectedUserData?.firstName}
                    invalid={firstName?.length < 1}
                  />
                  <ListGroupItem type="text" label="Last Name" id="lastName-text"
                    onChangeHandler={(e) => setLastName(e.target.value)} validationMessage="Enter a valid 'Last Name'"
                    value={selectedUserData?.lastName}
                    invalid={lastName?.length < 1}
                  />
                  <ListGroupItem type="text" label="Username" id="username-text"
                    onChangeHandler={(e) => setUsername(e.target.value)} validationMessage="Enter a valid 'Username'"
                    value={selectedUserData?.username}
                    invalid={username?.length < 3}
                  />
                  {
                    !hideChangePasswordCheckbox &&
                    <ListGroupItem type="checkbox" label="Change Password" id="changePassword-checkbox"
                      onChangeHandler={(e) => setChangePassword(e.target.checked)} validationMessage=""
                      style={{ display: "none" }}
                    />
                  }
                  {
                    changePassword &&
                    <>
                      <ListGroupItem type="password" label="Password" id="password-text"
                        onChangeHandler={(e) => setPassword(e.target.value)} validationMessage="Enter a valid 'Password'"
                        value=""
                        invalid={retypedPassword !== password || password.length < 3}
                      />
                      <ListGroupItem type="password" label="Retype Password" id="retypePassword-text"
                        onChangeHandler={(e) => setRetypedPassword(e.target.value)} validationMessage="Passwords do not match"
                        value=""
                        invalid={retypedPassword !== password}
                      />
                    </>
                  }
                  {!viewMode && <SaveButton2 onClickHandler={saveUser} />}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

const SaveButton2 = (props) => {
  return (
    <li className="list-group-item text-end">
      <SaveButton onClickHandler={props.onClickHandler} />
    </li>
  )
}
export default AddEditUser;