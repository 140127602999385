import { makeGetCall, makePostCall, makePutCall } from "./mainHook";

const getAllVisits = (searchText, successHandler, errorHandler) => {
  makeGetCall(`visit?search=${searchText}`, successHandler, errorHandler);
}

const getVisitById = (id, successHandler, errorHandler) => {
  makeGetCall(`visit/${id}`, successHandler, errorHandler);
}

const getVisitsByPatientId = (patientId, successHandler, errorHandler) => {
  makeGetCall(`visit/patient/${patientId}`, successHandler, errorHandler);
}

const updateVisit = (params, successHandler, errorHandler) => {
  return makePutCall("visit", params, successHandler, errorHandler);
}

const addVisit = (params, successHandler, errorHandler) => {
  return makePostCall("visit", params, successHandler, errorHandler);
}

const getLastVisitByPatientId = (patientId, successHandler, errorHandler) => {
  makeGetCall(`patient/${patientId}/last/visit`, successHandler, errorHandler);
}

export {
  getAllVisits,
  getVisitById,
  updateVisit,
  addVisit,
  getVisitsByPatientId,
  getLastVisitByPatientId,
}