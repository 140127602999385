import { logoutUser } from "../Entities/User/userManager";

const handleResponseError = (error, errorsSetter) => {
  if (errorsSetter === null) {
    errorsSetter = () => { }
  }
  try {
    if (error?.response?.status === 401) {
      errorsSetter(['session_timed_out']);
      logoutUser();
      window.location.reload();
    } else if (error?.response?.status === 404) {
      errorsSetter(["Make sure server is reachable"]);
      return;
    } else if (error?.response?.status === 400) {
      if (Array.isArray(error?.response?.data)) {
        errorsSetter(error?.response?.data);
      } else {
        if (error?.response?.data?.trace) {
          errorsSetter(['unknown client error'])
          console.log(error?.response?.data?.trace.substr(1, 1000))
        } else {
          errorsSetter([error?.response?.data]);
        }
      }
      return error;
    } else {
      console.log(error?.response?.data, error)
      if (error?.code === "ERR_NETWORK") {
        errorsSetter(["Make sure server is reachable"]);
      } else {
        errorsSetter(error?.response?.data);
      }
      return error;
    }
  } catch (ex) {
    console.log(ex);
    errorsSetter(["Make sure server is reachable"]);
  }
}
export {
  handleResponseError,
}
/*
if (error.response) {
  // server responded with a status code that falls out of the range of 2xx
  console.log("1", error.response.data, error.response.status, error.response.headers);
} else if (error.request) {
  // The request was made but no response was received
  console.log("2", error.request);
} else {
  // Something happened in setting up the request that triggered an Error
  console.log('bbb-error in request: ', error.message);
}
*/