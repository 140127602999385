import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "../ActionButton";

const AppointmentLine = (props) => {
  const [appointment, setAppointment] = useState({});
  useEffect(() => {
    setAppointment(props?.appointment);
  }, [props]);
  let history = useNavigate();
  const handleClickEdit = (e) => {
    history("/editAppointment", { state: { patient: props.patient, appointment: props.appointment, referrer: "patient/appointments" } });
  }
  const handleClickView = (e) => {
    history("/viewAppointment", { state: { patient: props.patient, appointment: props.appointment, referrer: "patient/appointments" } });
  }
  return (
    <div key={appointment.id} className="alert alert-light" role="alert">
      <div className="row">
        <div className="col">
          Reason: {appointment.reason} {appointment.lastName}  <br />
          Date And Time: {appointment.dateAndTime} <br />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col text-start">
          <ActionButton caption="View" onClick={handleClickView} icon={<VisibilityOutlined />} />
          <ActionButton caption="Edit" onClick={handleClickEdit} icon={<EditOutlined />} />
        </div>
      </div>
    </div>
  )
}
export default AppointmentLine;