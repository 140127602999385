const AddButton = (props) => {
  return (
    <div className="row mb-2">
      <div className="col text-end">
        <button type="button" className="me-1 btn btn-primary" aria-label="Close" onClick={props.onClickHandler}>Add</button>
      </div>
    </div>
  )
}

export default AddButton;