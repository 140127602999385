import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import React, { createRef, useEffect, useState } from 'react'
import timeGridPlugin from '@fullcalendar/timegrid'
import PageTitle from '../Components/PageTitle';
import ErrorAlert from '../Components/ErrorAlert';
import { getAppointmentsByRange } from '../Hooks/appointmentHook';
import { handleResponseError } from "../Commons/errorHandler";
import { useNavigate } from 'react-router-dom';

export default function Appointments() {
  let navigate = useNavigate();
  const [errorsList, setErrorsList] = useState([]);
  const [events, setEvents] = useState([]);
  const [calendarInitialView, setCalendarInitialView] = useState("timeGridWeek");
  const [selectedCalendarRange, setSelectedCalendarRange] = useState({});
  const calendarRef = createRef();
  const events1 = [
    { id: -1, title: 'event a', start: '2023-09-19 10:10:00', },
    { id: 0, title: 'event 0', start: '2023-09-19 11:10:00', end: '2023-09-19 12:10:00' },
    { id: 1, title: 'event 1', start: '2023-09-19 17:10:00', end: '2023-09-19 18:10:00', url: "editAppointment" },
    { id: 2, title: 'event 2', date: '2023-09-23' },
  ];

  const handleDateClick = (args) => {
    console.log(JSON.stringify(args))
  }
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b> &nbsp;
        <i>{eventInfo.event.title}</i>
      </>
    )
  }
  const handleEventClick = (clickInfo) => {
    console.log(JSON.stringify(clickInfo.event?.extendedProps?.appointmentData))
    navigate('/viewAppointment', { state: { appointment: clickInfo.event?.extendedProps?.appointmentData } })
    //if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.id}' - '${clickInfo.event.title}'`)) {
    // clickInfo.event.remove()
    // return;
    //}
  }
  useEffect(() => {
    //console.log("dates", calendarRef);
    if (calendarRef === null) {
      return;
    }
    //console.log('calendarRef', calendarRef);
    let calendarApi = calendarRef.current.getApi()
    let dates = calendarApi.currentDataManager.state.dateProfile.activeRange
    setSelectedCalendarRange(dates)
    // console.log(dates);
  }, [calendarRef]);

  function convertDate(d) {
    //console.log("d", d)
    if (d === undefined) {
      return "";
    }
    function pad(s) { return (s < 10) ? '0' + s : s; }
    //var d = date;//new Date(inputFormat)
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
  }
  useEffect(() => {
    // get current view date
    if (selectedCalendarRange === null || selectedCalendarRange?.start === undefined || selectedCalendarRange?.end === undefined) {
      return;
    }
    setErrorsList([]);
    // format the dates
    let fromDate = selectedCalendarRange.start;
    if (fromDate === "") {
      return;
    }
    let toDate = selectedCalendarRange.end;
    fromDate = convertDate(fromDate);
    toDate = convertDate(toDate);
    // call hook to load appointments for selectedRange
    let successHandler = (response) => {
      // fill events list
      // setEvents([]);
      let eventsTemp = [];
      response?.data.map((value, key) => {
        eventsTemp.push(
          {
            id: value.id,
            title: value.reason + (value.appointmentClosureType !== null ? " (" + value.appointmentClosureType.name + ")" : ""),
            start: value.dateAndTime.replace("T", " "),
            appointmentData: value,
            //allDay: true,
            backgroundColor: value.appointmentClosureType === null ? "#F00" : "#0F0",
            //textColor : value.appointmentClosureType === null ? "black" : "#0FF",
          }
        );
        //setEvents([...events, { id: value.id, title: value.reason, start: value.dateAndTime.replace("T", " ") }])
      })
      setEvents(eventsTemp);
    }
    let errorHandler = (error) => {
      return handleResponseError(error, setErrorsList);
    }
    getAppointmentsByRange(fromDate, toDate, successHandler, errorHandler);
    // we can use `handleEventClick` instead of url to open the view
  }, [selectedCalendarRange]);

  return (
    <>
      <PageTitle title="Appointments" />
      <ErrorAlert errors={errorsList} hide={errorsList?.length === 0} />
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={calendarInitialView}
        weekends={true}
        events={events}
        dateClick={handleDateClick}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        datesSet={
          (dateInfo) => {
            setSelectedCalendarRange(dateInfo)
            //alert('The new title of the view is ' + JSON.stringify(dateInfo));
          }
        }
        contentHeight='70vh'
        // eventBackgroundColor="#FF0000" // this won't work in case `backgroundColor` property was set on event object
      />
    </>
  )
}
