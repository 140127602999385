import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import MainSidebar from './MainSidebar'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getCurrentUser, logoutUser } from '../Entities/User/userManager';
import { useNavigate } from 'react-router-dom';

export default function MainNavbar() {
  let navigate = useNavigate();

  const [welcomeMessage, setWelcomeMessage] = useState("");

  const handleLogout = () => {
    logoutUser()
    window.location.reload();
  }

  const handleMyProfile = () => {
    let path = document.location.pathname;
    navigate("/myProfile", { state: { user: getCurrentUser(), referrer: path } });
  }

  const handleChangePassword = () => {
    navigate("/changePassword", { state: {} });
  }

  useEffect(() => {
    setWelcomeMessage(`Welcome, ${getCurrentUser()?.username}`)
  }, [getCurrentUser()]);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="#">
          <img src={'images/logo-60x60.png'} alt='Shifaa' style={{ maxHeight: '40px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="home">
            </Nav.Link>
            <MainSidebar />
            <Nav.Link className='d-none' href="#action2">Link1</Nav.Link>
            <Nav.Link className='d-none' href="#" disabled>
              Link2
            </Nav.Link>
          </Nav>
          {/*
            Putting the NavDropdown here below <Nav> will position it at the end
           align='end' to fix the position of the submenu
          */}
          <span>{welcomeMessage}</span>
          <NavDropdown title={<PersonOutlineOutlinedIcon />} align='end' id="navbarScrollingDropdown">
            <NavDropdown.Item href="#" onClick={handleMyProfile}>My Profile</NavDropdown.Item>
            <NavDropdown.Item href="#" onClick={handleChangePassword}>
              Change Password
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#" onClick={handleLogout}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
          {/*
           <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
           */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
