const CloseButton = (props) => {
  return (
    <div className="row mb-1">
      <div className="col text-end">
        <button type="button" className="btn-close" aria-label="Close" onClick={props.onClickHandler}></button>
      </div>
    </div>
  )
}

export default CloseButton